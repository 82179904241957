




























































import router from "@/router";
import {
  computed,
  defineComponent,
  onUnmounted,
  reactive,
  SetupContext,
} from "@vue/composition-api";
import { ERoutes } from "@/core/dataTypes/enum";
import { CreateHdCustomerVehicleRequest } from "@/core/dataTypes/types";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";
import { validationRegExp } from "@/core/utils/validation/validationRegExp";
import { vehiclePriceCategories } from "@/core/utils/vehicle";
import VehicleAddForm from "@/components/vehicle/VehicleAddForm/VehicleAddForm.vue";
import { i18n } from "@/locales/i18n";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import { plateNumberValid } from "@/core/api/accountService.api";
import { DynamicObject } from "@/core/dataTypes/types/common.interface";
import { Subscription } from "rxjs";
import httpService from "@/core/services/http.service";

export default defineComponent({
  name: "VehicleNewPage",
  components: {
    VehicleAddForm,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup(props: any, { refs }: SetupContext) {
    vehicleFacade.setPlateNumberLoading(false);
    const countryCodeErrorSubscription = new Subscription();
    const countries = computed(vehicleFacade.countries);
    const createHdCustomerVehicleLoading = computed(
      vehicleFacade.createHdCustomerVehicleLoading
    );
    const vehicleFormSubmitLoading = computed(
      vehicleFacade.vehicleFormSubmitLoading
    );
    const formValues = reactive({
      countryCode: "",
      plateNumber: "",
      hdVehicleCategoryId: "",
    });
    const submitVehicleNewForm = () => {
      vehicleFacade.setVehicleFormSubmitLoading(true);
      validatePlateNumber().then((valid) => {
        if (valid) {
          const form: CreateHdCustomerVehicleRequest = {
            hdVehicle: {
              countryCode: formValues.countryCode,
              plateNumber: formValues.plateNumber,
              hdVehicleCategoryId: formValues.hdVehicleCategoryId,
              isoCode: countries.value?.find(
                (country) => country.countryCode === formValues.countryCode
              )?.isoCode,
            },
          };
          vehicleFacade.createHdCustomerVehicle(form);
        }
      });
    };
    const navigateToVehiclePage = () => {
      router.push(getLanguageStaticRoute(ERoutes.VEHICLE));
    };

    countryCodeErrorSubscription.add(
      httpService.getCountryCodeError().subscribe(() => {
        const errorObject: DynamicObject = {};
        errorObject[i18n.tc("VEHICLE_NEW_PAGE.FIELDS.COUNTRY_CODE")] = i18n.tc(
          "HTTP_ERRORS.INVALID_COUNTRY_CODE"
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (refs.form as any).setErrors(errorObject);
      })
    );

    const onPlateNumberValidationBlurred = () => {
      if (formValues.plateNumber) {
        vehicleFacade.setPlateNumberLoading(true);
        validatePlateNumber();
      }
    };

    const validatePlateNumber = (): Promise<boolean> => {
      resetErrors();
      return plateNumberValid({
        plateNumber: formValues.plateNumber,
        countryCode: formValues.countryCode,
      })
        .then((response) => {
          vehicleFacade.setPlateNumberLoading(false);
          vehicleFacade.setVehicleFormSubmitLoading(false);
          if (response.validationResult === "INVALID_PLATE_NUMBER") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (refs.form as any).setErrors(setErrorObject(true));
            return false;
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (refs.form as any).setErrors(setErrorObject(false));
            return true;
          }
        })
        .catch((error) => {
          vehicleFacade.setPlateNumberLoading(false);
          vehicleFacade.setVehicleFormSubmitLoading(false);
          throw Error(error);
        });
    };

    const resetErrors = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (refs.form as any).setErrors(setErrorObject(false));
    };

    const setErrorObject = (valid: boolean): DynamicObject => {
      const errorObject: DynamicObject = {};
      errorObject[i18n.tc("VEHICLE_NEW_PAGE.FIELDS.PLATE_NUMBER")] = valid
        ? i18n.tc("VALIDATION.REGEX", 0, {
            name: i18n.tc("VEHICLE_NEW_PAGE.FIELDS.PLATE_NUMBER"),
          })
        : null;
      return errorObject;
    };

    onUnmounted(() => {
      countryCodeErrorSubscription.unsubscribe();
    });

    return {
      formValues,
      countries,
      navigateToVehiclePage,
      submitVehicleNewForm,
      validationRegExp,
      createHdCustomerVehicleLoading,
      vehicleFormSubmitLoading,
      vehiclePriceCategories,
      onPlateNumberValidationBlurred,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("VEHICLE_NEW_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
